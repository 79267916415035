import { admitadUidCookie } from '@config';
import { storeConfigVar } from '@core/services/graphql/cache';

export const setAdmitadCookie = (admitad_uid = '') => {
    const pwaConfig = storeConfigVar();
    const isAdmitadUidExist = !!admitad_uid;
    // Cookie lifetime (days)
    const daysToStore = parseInt(pwaConfig?.admitad_cookies, 10) || 30;

    // Function to get parameter value from the URL
    const getParamFromUriAdmitad = (getParamName) => {
        const pattern = `${getParamName}=([^&]+)`;
        const re = new RegExp(pattern);
        return (re.exec(window.location.search) || [])[1] || '';
    };

    const param = getParamFromUriAdmitad('admitad_uid');
    if (!param) return;

    const period = daysToStore * 60 * 60 * 24 * 1000; // in milliseconds
    const expiresDate = new Date(Date.now() + period).toUTCString();

    // Setting the cookie
    if ((!isAdmitadUidExist && admitad_uid !== param) || (isAdmitadUidExist && admitad_uid !== param)) {
        const cookieString = `${admitadUidCookie}=${param}; path=/; expires=${expiresDate}`;
        document.cookie = cookieString;
        document.cookie = `${cookieString}; domain=.${window.location.host}`;
    }
};

export default {
    setAdmitadCookie,
};
